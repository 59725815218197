<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex" style="background-color: red">
          <div style="font-size: 50px" class="p-0">
            <img src="@/assets/img/ifoodLogo.png" style="width: 100px" />
          </div>
          <div style="font-size: 30px" class="p-0"></div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  mounted() {
  },
  data() {
    return {
    };
  },
  watch: {
   
  },
  computed: {},
  methods: {
   
  },
};
</script>

<style lang="scss" scoped></style>
